<template>
  <v-container fill-height>
    <v-layout>
      <v-flex xs12 sm8 md10 lg8 xl6 offset-sm2 offset-md1 offset-lg2 offset-xl3>
        <sign-user-card
          :user-options="userOptions"
          :status="status"
          :error="error"
          class="pa-4"
          @onSignUp="onSignUp"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import { SignUserCard } from '@/components/User'

const props = {
  token: {
    type: String,
    default: ''
  }
}

export default {
  name: 'RegistrationPage',
  components: { SignUserCard },
  data: () => ({
    userOptions: {},
    reset: false,
    forgotten: false,
    fetching: true,
    status: ''
  }),
  props,
  computed: {
    ...mapState('users', ['error'])
  },
  methods: {
    async onSignUp(user) {
      await this.$store.dispatch('users/REGISTER_USER', {
        ...user,
        registration_token: this.token
      })
      if (!this.error) {
        this.status = 'OK'
        this.$router.push('/login')
      }
      this.status = 'FAILED'
    }
  },
  async mounted() {
    const data = await this.$store.dispatch(
      'users/FETCH_REGISTRATION',
      this.token
    )
    this.fetching = false
    // force submit button in SignUserCard to be Sign Up instead of done setting status to FAILED
    if (!this.error) { this.status = 'FAILED' }
    const { email, name } = data || {}
    this.userOptions = { email, name }
  }
}
</script>
